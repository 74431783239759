import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { useQueryParams, BooleanParam } from "use-query-params";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import { Button, Row, Container, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import Layout from "../../components/Layout";
import SidebarAccount from "../../components/SidebarAccount";
import { accountLogout, regionRequest } from "../../actions";
import NavigateButton from "../../components/NavigateButton";
import NavLink from "../../components/NavLink";

const Logout = (props) => {
  const { t, i18n } = useTranslation();

  const { dispatch, account_is_logged_in } = props;
  const { language } = useI18next();

  useEffect(() => {
    document.dir = i18n.dir();
    dispatch(regionRequest(language));
  }, [dispatch, language, i18n]);

  const [logoutMessage, setLogoutMessage] = useState({
    message: "You have successfully logged out.",
    variant: null,
    button: null,
  });
  const [query] = useQueryParams({
    force: BooleanParam,
    reset: BooleanParam,
  });
  const { force: forceLogout, reset: resetPassword } = query;

  // useEffect to determine logout message and status
  useEffect(() => {
    const logout = () => {
      dispatch(accountLogout());
    };
    const loginButton = (
      <NavigateButton
        buttonClass="btn btn-primary btn-lg"
        wrapperClass="account-container-btn"
        path="/account/login/"
        buttonText={t("Log In")}
      />
    );
    const logoutButton = (
      <Button variant="primary" onClick={logout}>
        <Trans>Yes, log me out.</Trans>
      </Button>
    );

    if (forceLogout || resetPassword) {
      logout();
      // shows logged out message due to session expiry
      if (forceLogout) {
        setLogoutMessage({
          message: t(
            "You have been logged out due to expired session. Please log in again."
          ),
          variant: "warning",
          button: loginButton,
        });
      }
      // shows logged out message due to reset password
      if (resetPassword) {
        setLogoutMessage({
          message: t(
            "Your password was changed successfully. Please log in with your new credentials to continue."
          ),
          variant: "success",
          button: loginButton,
        });
      }
      // shows logged out message after user has confirmed logging out
    } else if (!account_is_logged_in) {
      setLogoutMessage({
        message: t("You have successfully logged out."),
        variant: "success",
        button: loginButton,
      });
      // shows log out confirmation
    } else {
      setLogoutMessage({
        message: t("Are you sure?"),
        button: logoutButton,
      });
    }
  }, [forceLogout, resetPassword, account_is_logged_in, dispatch, t]);

  return (
    <Layout
      pageName="logout"
      secondaryNav={
        <NavLink
          linkPath="/"
          linkText="Map"
          linkIcon={<FontAwesomeIcon icon={faMap} size="lg" />}
        />
      }
    >
      <Container fluid>
        <SidebarAccount {...props} />
        <div
          className={`account-container ${
            language === "ar" ? "custom-rtl-dir" : ""
          }`}
        >
          <Row>
            <Col>
              <h2 className="py-4">
                <Trans>Log out</Trans>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                className={
                  logoutMessage.variant
                    ? `alert alert-${logoutMessage.variant}`
                    : ""
                }
              >
                {logoutMessage.message}
              </p>
              {!!logoutMessage.button && logoutMessage.button}
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default connect((state) => state)(Logout);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
