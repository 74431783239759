import React from "react";
import { Link } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HeaderMainLogo from "./HeaderMainLogo";
import GeoGuardLogo from "../assets/images/geoguard-logo@2x.png";
import CrafdLogo from "../assets/images/CRAFd-DPPA-logo.svg";

const SidebarAccount = (props) => {
  const { path } = props;
  const { language } = useI18next();

  return (
    <aside className="sidebar sidebar-account">
      <div className="sidebar-container">
        <HeaderMainLogo logo={<img src={GeoGuardLogo} alt="GeoGuard Logo" />} />

        {props ? (
          <>
            <div
              className={`sidebar-back-to-app ${
                language === "ar" ? "rtl" : ""
              }`}
            >
              <Link to="/">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                <Trans i18nKey="Back to GeoGuard">Back to GeoGuard</Trans>
              </Link>
            </div>
            <div className="sidebar-account-menu">
              <div className="sidebar-account-menu-section">
                <div className="sidebar-account-menu-header">
                  <Trans>Account</Trans>
                </div>
                <Link to="/account">
                  <div
                    className={
                      path === "/account/"
                        ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                        : "sidebar-account-menu-item"
                    }
                  >
                    <Trans>Profile</Trans>
                  </div>
                </Link>
              </div>
              <div className="sidebar-account-menu-section">
                <div className="sidebar-account-menu-header">
                  <Trans i18nKey="helpTitle">Documentation & Help</Trans>
                </div>
                <Link to="/help-about">
                  <div
                    className={
                      path === "/help-about/"
                        ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                        : "sidebar-account-menu-item"
                    }
                  >
                    <Trans>About</Trans>
                  </div>
                </Link>
                <Link to="/help-faq">
                  <div
                    className={
                      path === "/help-faq/"
                        ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                        : "sidebar-account-menu-item"
                    }
                  >
                    <Trans i18nKey="faqsNavText">FAQs</Trans>
                  </div>
                </Link>
                <Link to="/help-contact">
                  <div
                    className={
                      path === "/help-contact/"
                        ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                        : "sidebar-account-menu-item"
                    }
                  >
                    <Trans i18nKey="contactTitle">Contact</Trans>
                  </div>
                </Link>
              </div>
            </div>
            <div className="sidebar-button-section">
              <Link variant="primary" to="/account/logout" block>
                <Button variant="primary">
                  <Trans>Log Out</Trans>
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <div className="sidebar-account-menu-section">
            <div className="sidebar-account-menu-header">
              <Trans>Account</Trans>
            </div>
            <Link to="/account/login">
              <div
                className={
                  path === "/account/login"
                    ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                    : "sidebar-account-menu-item"
                }
              >
                <Trans>Log In</Trans>
              </div>
            </Link>
            <Link to="/account/register">
              <div
                className={
                  path === "/account/register"
                    ? "sidebar-account-menu-item sidebar-account-menu-item-active"
                    : "sidebar-account-menu-item"
                }
              >
                <Trans>Register</Trans>
              </div>
            </Link>
          </div>
        )}
        <div className="sidebar-footer">
          <img
            src={CrafdLogo}
            alt="Crafd and DPPA Logo"
            style={{ height: 30 }}
          />
        </div>
      </div>
    </aside>
  );
};

export default SidebarAccount;
