import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Trans } from "gatsby-plugin-react-i18next";

/**
 * shows
 * @param {string} [href="/"] -
 * @param {HTMLImageElement} [logo=<img/>]
 * @param {string} [title=""]
 * @returns {any}
 */
const LogoHeader = ({ href = "/", logo = <img />, title = "" }) => (
  <header className="logo-header">
    <Link to={href}>
      <span className="logo-wrap">{logo}</span>
      {title && <span className="">{title}</span>}
    </Link>
    <div className="logo-text">
      <Trans i18nKey="logoText-1">A curated dashboard</Trans>
      <br />
      <Trans i18nKey="logoText-2">using open source data</Trans>
    </div>
  </header>
);

LogoHeader.propTypes = {
  href: PropTypes.string,
  logo: PropTypes.element,
  title: PropTypes.string,
};

export default LogoHeader;
