import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby-link";

/**
 * Button component using gatsby's navigate helper function
 * @param {any} [path="/"] - Internal path to navigate to
 * @param {any} [buttonText="&lt; go back"] - Button display text
 * @param {any} [buttonClass="btn btn-primary"] - Button className
 * @param {any} [wrapperClass=""] - className for the button's parent div element
 * @param {any} [beforeNavigate=() => {}] - function to run before navigating
 */
const NavigateButton = ({
  path = "/",
  buttonText = "&lt; go back",
  buttonClass = "btn btn-primary",
  wrapperClass = "",
  beforeNavigate = () => {},
}) => {
  const handleNavigateOut = (event) => {
    event.preventDefault();
    beforeNavigate();
    navigate(path);
  };

  return (
    <div className={wrapperClass}>
      <button onClick={handleNavigateOut} className={buttonClass}>
        {buttonText}
      </button>
    </div>
  );
};

NavigateButton.propTypes = {
  path: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  beforeNavigate: PropTypes.func,
};

export default NavigateButton;
