import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const NavLink = ({
  linkPath = "/",
  linkText = "",
  linkIcon,
  disabled = false,
}) => {
  let secondaryClassName = "header-text-link";

  if (linkIcon) {
    secondaryClassName = "header-icon-link text-center";
  }

  return (
    <Link
      to={linkPath}
      className={`nav-link header-link ${secondaryClassName}`}
      disabled={disabled}
    >
      {linkIcon || linkText}
    </Link>
  );
};

NavLink.propTypes = {
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  linkIcon: PropTypes.element,
  disabled: PropTypes.bool,
};

export default NavLink;
